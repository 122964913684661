<template>
  <div>
    <div id="viewer" ref="viewer"></div>
  </div>
</template>
 
<script>
var THREE = require('three')
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
export default {
  name: 'quanjing',
  data() {
    return {
      //factoryLink: require('@/assets/b.jpg'),
    }
  },
  watch: {},
  mounted() {
    console.log(THREE)
    console.log(Viewer)

    this.init()
    //     if (this.PSV) {
    //         this.imageLoaded = false;
    //         console.log(this.imageLoaded);
    //         this.PSV.setPanorama(this.factoryLink, true, true).then(() => {
    //             this.imageLoaded = true;
    //             console.log("-------替换图片完成--------");
    //         });
    //     } else {
    //         this.initPhotoSphere();
    //     }
  },
  methods: {
    init() {
      let domain='https://fzszghbk1.oss-cn-hangzhou.aliyuncs.com/vr/'
      //获取微信小程序传过来的全景图地址
      var param = getParamer()
      //获取并处理小程序传递过来的参数
      function getParamer() {
        var url = window.location.href.split('?')[1] /*获取url里"?"后面的值*/
        if (url) {
          /*判断是否是一个参数还是多个参数*/
          url = url.split('=')
          return url[1] /*返回想要的参数值*/
        } else {
          return ''
        }
      }
      param = domain + param
      this.PSV = new Viewer({
        container: document.querySelector('#viewer'),
        panorama: param ? param : '', //这里放全景图地址
        caption: '实景VR',
        navbar: ['autorotate', 'zoom', 'caption', 'fullscreen'],
      })
    },
  },
}
</script>
 
<style scoped>
#viewer {
  width: 100vw;
  height: 100vh;
}
</style>